var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.detailsView)?_c('div',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('v-btn',{attrs:{"large":"","depressed":"","tile":""},on:{"click":function($event){return _vm.addUser()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" Add user ")],1),_c('v-col',{attrs:{"cols":"4"}},[_c('search-text-field',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-checkbox',{attrs:{"label":"Show disabled"},on:{"change":_vm.getUsers},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}})],1),(!_vm.detailsView)?_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search},on:{"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.roles.join(', '))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'UserDetails', params: { Id: item.id } }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('readonly-checkbox',{attrs:{"value":!item.disabled}})]}},{key:"item.prereleaseEnabled",fn:function(ref){
var item = ref.item;
return [_c('readonly-checkbox',{attrs:{"value":item.prereleaseEnabled}})]}},{key:"item.latestEulaAccepted",fn:function(ref){
var item = ref.item;
return [(item.latestEulaAccepted)?_c('span',[_vm._v(_vm._s(_vm.$util.formatDate(item.latestEulaAccepted)))]):_vm._e()]}}],null,true)},'v-data-table',_vm.$util.dataTableAttributes(),false)):_vm._e()],1):_c('user-details',{on:{"user-updated":_vm.getUsers}}),_c('create-edit-user',{ref:"CreateEditUserRef",on:{"user-updated":_vm.getUsers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }